import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {readPost, unloadPost} from "../../modules/post";
import PostViewer from "../../components/post/PostViewer";
import {useNavigate, useParams} from "react-router-dom";
import PostActionButtons from "../../components/post/PostActionButtons";
import {setOriginalPost} from "../../modules/write";
import {deletePost} from "../../lib/api/posts";

const PostViewerContainer = () => {
    const {postId} = useParams();
    const navigate = useNavigate();
    // 처음 마운트될 때 포스트 일기 API 요청
    const dispatch = useDispatch();

    const {post, postError, loading, user} = useSelector(({post, loading, user}) => ({
        post: post.post,
        postError: post.postError,
        loading: loading['post/READ_POST'],
        user: user.user
    }));

    // 언마운트될 때 초기화
    useEffect(() => {
        console.log(" post view : " + postId)
        if(!postId) return;
        dispatch(readPost(postId));
        return () => {
            dispatch(unloadPost());
        };
    }, [dispatch, postId]);

    const onEdit = () => {
        dispatch(setOriginalPost(post));
        navigate('/write');
    }

    const onRemove = async () => {
        try{
            await deletePost(post._id);
            navigate('/');
        }catch (e) {
            console.log(e)
        }

    }

    const ownPost = (user && user._id) === (post && post.user._id);

    return <PostViewer post={post} loading={loading} error={postError} actionButtons={ownPost && <PostActionButtons onEdit={onEdit} onRemove={onRemove}/>}/>

};

export default PostViewerContainer;