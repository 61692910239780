import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {readNews} from "../../modules/news";
import NewsCard from "./NewsCard";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



const NewsListBlock = styled.div`
  box-sizing: border-box;
  padding-bottom: 3rem;
  width: 768px;
  margin: 2rem auto 0;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const NewsList = ({category}) => {
    const dispatch = useDispatch();
    const {news, newsError, loading} = useSelector(({news, loading}) => ({
        news: news.news,
        newsError: news.newsError,
        loading: loading['news/READ_NEWS'],
    }));

    // 언마운트될 때 초기화
    useEffect(() => {
        let addUrl = "";
        if (!category || category !== "all") {
            addUrl = "?category=" + category;
        }
        dispatch(readNews(addUrl));
    }, [dispatch, category]);

    // 대기 중일 때
    if (loading) {
        return (
            <NewsListBlock>
                <Row xs={1} md={2} className="g-4">
                    <Col><NewsCard key={"template"} article={{}}/></Col>
                </Row>
            </NewsListBlock>
        );
    }

    if (newsError && !news) {
        return <NewsListBlock>에러 발생하였습니다.</NewsListBlock>;
    }

    if (!news || !news.data) {
        return <NewsListBlock>데이터가 없습니다.</NewsListBlock>;
    }

    const articles = news.data;

    return (
        <NewsListBlock>
            <Row xs={1} md={2} className="g-4">
                {articles.map(article =>
                    (<Col><NewsCard key={article.url} article={article}/></Col>)
                )}
            </Row>
        </NewsListBlock>
    );
}

export default NewsList;