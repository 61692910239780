import styled from "styled-components";
import Responsive from "../common/Responsive";
import palette from "../../lib/styles/palette";
import Tags from "../common/Tags";
import Button from "../common/Button";
import {useNavigate} from "react-router-dom";
import Card from 'react-bootstrap/Card';

const PostListBlock = styled(Responsive)`
  margin-top: 3rem;
`;

const UserInfoBlock = styled.div`
    cursor: pointer;
    display: flex;
    margin-right: 1rem;
`;

const WritePostButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const PostItemBlock = styled.div`

  padding-top: 1rem;
  padding-bottom: 1rem;
  /* 맨 위 포스트는 padding-top 없음 */

  &:first-child {
    padding-top: 0;
    margin-top: 0;
  }

  // & + & {
    //     border-top: 1px solid ${palette.gray[2]};
  // }
  // h2 {
  //     font-size: 2rem;
  //     margin-bottom: 0;
  //     margin-top: 0;
  //     &:hover{
    //         color: ${palette.gray[6]};
  //     }
  // }
  // p {
  //     margin-top: 2rem;
  // }
`;

const CardHeaderWrapper = styled(Card.Header)`
  cursor: pointer;
`;

const buildLink = ({user, _id}) => {
    return user?.username ? `/@${user.username}/${_id}` : `/${_id}`;
}

const PostItem = ({post}) => {
    const {publishedDate, user, tags, title, body, _id} = post;
    const navigate = useNavigate();
    const titleHandler = (event, user, _id) => {
        navigate(buildLink({user, _id}));
    }
    const userNameHandler = (event, user) => {
        if(!user?.username) return;
        const mvName = '/@' + user.username;
        navigate(mvName);
    }

    return (
        <PostItemBlock>
            {/*<h2><Link to={buildLink({user,_id})}>{title}</Link></h2>*/}
            {/*<SubInfo username={user?.username ? user.username : '사용자 없음' } publishedDate={publishedDate}/>*/}

            {/*<p>{body}</p>*/}
            <Card className>
                <CardHeaderWrapper onClick={
                    event => {
                        titleHandler(event, user, _id);
                    }
                }>{title}</CardHeaderWrapper>
                <Card.Body>
                    {/*<Card.Title>{title}</Card.Title>*/}
                    <Card.Text>
                        {body}
                    </Card.Text>
                    <Tags tags={tags}/>
                    {/*<Button variant="primary">Go somewhere</Button>*/}
                </Card.Body>
                <Card.Footer className="text-muted d-flex">
                    <UserInfoBlock
                        onClick={event => {
                        userNameHandler(event, user);
                    }}>
                        {user && user.username ?
                            user.username
                            :
                            '사용자 없음'
                        }
                    </UserInfoBlock>

                    <div>
                        {new Date(publishedDate).toLocaleDateString()}
                    </div>

                </Card.Footer>
            </Card>
        </PostItemBlock>
    )
        ;
}

const PostList = ({posts, loading, error, showWriteButton}) => {
    if (error) {
        return <PostListBlock>에러가 발생했습니다.</PostListBlock>;
    }


    return (
        <PostListBlock>
            <WritePostButtonWrapper>
                {showWriteButton && (
                    <Button cyan to={"/write"}>
                        새 글 작성하기
                    </Button>
                )}
            </WritePostButtonWrapper>
            {!loading && posts && (
                <div>
                    {posts.map(post => (
                        <PostItem post={post} key={post._id}/>
                    ))}
                </div>
            )}

        </PostListBlock>
    );
}
export default PostList;
