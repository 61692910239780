import AuthForm from "./AuthForm";
import {useDispatch, useSelector} from "react-redux";
import {changeField, initializeForm, login} from "../../modules/auth";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {check} from "../../modules/user";

const LoginForm = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {form, auth, authError, user} = useSelector(({auth, user}) => ({
        form: auth.login,
        auth: auth.auth,
        authError: auth.authError,
        user: user.user
    }));
    // 인풋 변경 이벤트 핸들러
    const onChange = e => {
        const {value, name} = e.target;
        dispatch(
            changeField({
                form: 'login',
                key: name,
                value
            })
        );
    }

    // 폼 등록 이벤트 핸들러
    const onSubmit = e => {
        e.preventDefault();
        // 하나라도 비어 있다면
        const {username, password} = form;

        if ([username, password].includes('')) {
            setError('빈 칸을 모두 입력하세요...');
            return;
        }
        dispatch(login({username, password}));

    }

    // 컴포넌트가 처음 렌더링될 때 form을 초기화함
    useEffect(() => {
            dispatch(initializeForm('login'));
        }, [dispatch]
    );

    useEffect(() => {
            if (authError) {
                console.log('오류 발생');
                setError("로그인 실패");
                return;
            }
            if (auth) {
                // console.log('로그인 성공');
                dispatch(check());
            }
        }, [auth, authError, dispatch]
    );

    useEffect(() => {
            if (user) {
                navigate("/");
                try {
                    localStorage.setItem("user", JSON.stringify(user));
                } catch (e) {
                    console.log(" localstorage is not working");
                }
                // console.log("check api 성공");
                // console.log(user)
            }
        }, [navigate, user]
    );

    return (
        <AuthForm type={"login"}
                  form={form}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  error={error}
        />
    )
}
export default LoginForm;