import NewsList from "../components/news/NewsList";
import {useParams} from "react-router-dom";
import HeaderContainer from "../containers/common/HeaderContainer";

const NewsPage = () => {
    const params = useParams();
    // 카테고리가 선택되지 않았으면 기본값 all로 사용
    const category = params.category || 'all';

    return (
        <>
            <HeaderContainer />
            <NewsList category={category}/>
        </>
    )
};

export default NewsPage;