import styled, {css} from "styled-components";
import palette from "../../lib/styles/palette";

import {useNavigate} from "react-router-dom";

const StyledButton = styled.button`
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.25rem 1rem;
    color: white;
    outline: none;
    cursor: pointer;
    
    background: ${palette.gray[8]};
    &:hover {
        background: ${palette.gray[6]};
    }
    ${props => 
        props.fullWidth && 
        css`
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            width: 100%;
            font-size: 1.125rem;
    `}

    ${props =>
    props.cyan &&
    css`
        background: ${palette.cyan[5]};
        &:hover {
            background: ${palette.cyan[4]};
        }
    `}
    &:disabled {
        background: ${palette.gray[3]};
        color: ${palette.gray[5]};
        cursor: not-allowed;
    }
    
`;

function Button ({ to, ...rest}){

    const history = useNavigate();
    const onClick = e => {
        // to 가 있다면 to 페이지로 이동
        if (to) {
            history(to);
        }
        if (rest.onClick) {
            rest.onClick(e);
        }
    };

    return (<StyledButton {...rest} onClick={onClick}/>)
};

export default Button;