import {useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Pagination from "../../components/post/Pagination";

const PostListContainer = () => {
    const [searchParams] = useSearchParams();

    const {username} = useParams();
    const tag = searchParams.get('tag');
    const page = parseInt(searchParams.get('page'), 10) || 1;
    const {lastPage, posts, loading} = useSelector(({posts, loading}) => ({
        lastPage: posts.lastPage,
        posts: posts.posts,
        loading: loading['posts/LIST_POST']
    }));

    if(!posts || loading) return null;

    return (
        <Pagination
            tag={tag}
            username={username}
            page={page}
            lastPage={lastPage}
        >
        </Pagination>
    );

};

export default PostListContainer;