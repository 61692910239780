import {useDispatch, useSelector} from "react-redux";
import {updatePost, writePost} from "../../modules/write";
import {useNavigate} from "react-router-dom";
import WriteActionButtons from "../../components/write/WriteActionButtons";
import React, {useEffect} from "react";


const WriteActionButtonsContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {title, body, tags, post, postError, originalPostId} = useSelector(({write}) => ({
        title: write.title,
        body: write.body,
        tags: write.tags,
        post: write.post,
        postError: write.postError,
        originalPostId: write.originalPostId
    }));

    // 포스트 등록
    const onPublish = () => {
        if(originalPostId){
            dispatch(
                updatePost({
                    title, body, tags, id: originalPostId
                })
            );
            return;
        }
        dispatch(
            writePost({
                title, body, tags,
            })
        );
    }

    const onCancel = () => {
        navigate(-1);
    }

    useEffect(() => {
        if(post){
            const {_id, user} = post;
            navigate(`/@${user.username}/${_id}`);
        }
        if(postError){
        }
    },[navigate, post, postError])

    return (
        <WriteActionButtons onPublish={onPublish} onCancel={onCancel} isEdit={!!originalPostId}/>
    )
}
export default WriteActionButtonsContainer;