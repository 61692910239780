import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styled from "styled-components";
import Placeholder from "react-bootstrap/Placeholder";

const CardImgBlock = styled(Card.Img)`
  height: 200px;
  cursor: pointer;
`;
const NewsCard = ({article}) => {
    const {title, description, url, urlToImage} = article;

    return (
        <>
            <Card style={{width: '18rem'}} className={"rounded mx-auto d-block"}>
                {urlToImage && (
                    <CardImgBlock variant="top" src={urlToImage} onClick={() => window.open(url, '_blank')?.focus()}/>
                )}
                {title !== undefined?
                    (
                        <Card.Body>
                            <Card.Title>{title}</Card.Title>
                            <Card.Text>{description}</Card.Text>
                            <Button variant="primary" onClick={() => window.open(url, '_blank')?.focus()}>Go</Button>
                        </Card.Body>
                    )
                    :
                    (
                        <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                <Placeholder xs={6}/> <Placeholder xs={8}/>
                            </Placeholder>
                            <Placeholder.Button variant="primary" xs={6}/>
                        </Card.Body>
                    )
                }

            </Card>

        </>
    );
}

export default NewsCard;