import './App.css';
import {Route, Routes} from "react-router-dom";
import PostListPage from "./pages/PostListPage";
import PostPage from "./pages/PostPage";
import WritePage from "./pages/WritePage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import {Helmet} from "react-helmet-async";
import NewsPage from "./pages/NewsPage";


function App() {
    return (
        <>
            <Helmet><title>DW React</title></Helmet>
            <Routes>
                <Route path="/" element={<PostListPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/write" element={<WritePage/>}/>
                <Route path="/@:username">
                    <Route index element={<PostListPage/>}/>
                    <Route path=":postId" element={<PostPage/>}/>
                </Route>
                <Route path="/news" element={<NewsPage/>}/>
                <Route path="/news/:category" element={<NewsPage/>}/>


            </Routes>
        </>
    );
}

export default App;
