import {combineReducers} from "redux";
import {all} from 'redux-saga/effects';
import auth, {authSaga} from "./auth";
import loading from "./loading";
import user, {userSaga} from "./user";
import write, {writeSaga} from "./write";
import post, {readSaga} from "./post";
import posts, {postListSaga} from "./posts";
import news, {fnReadNewSaga}  from "./news";

const rootReducer = combineReducers({
    auth,
    loading,
    user,
    write,
    post,
    posts,
    news
});

export function* rootSaga(){
    yield all([authSaga(), userSaga(), writeSaga(), readSaga(), postListSaga(), fnReadNewSaga()]);
}

export default rootReducer;