import {createAction, handleActions} from "redux-actions";
import createRequestSaga, {createRequestActionTypes} from "../lib/createRequestSaga";
import * as newsAPI from '../lib/api/news';
import {takeLatest} from 'redux-saga/effects';

const UNLOAD_NEWS = 'news/UNLOAD_NEWS';
const [
    READ_NEWS,
    READ_NEWS_SUCCESS,
    READ_NEWS_FAILURE,
] = createRequestActionTypes('news/READ_NEWS');

export const readNews = createAction(READ_NEWS,addUrl => addUrl);
export const unloadNewst = createAction(UNLOAD_NEWS);

const initialState = {
    news: null,
    newsError: null
};

// ({title,body,tags})
const readNewsSaga = createRequestSaga(READ_NEWS, newsAPI.readNews)

export function* fnReadNewSaga(){
    yield takeLatest(READ_NEWS, readNewsSaga);
}

const news = handleActions({
    [READ_NEWS_SUCCESS]: (state, {payload: news}) => ({
        ...state
        , news
    }),
    [READ_NEWS_FAILURE]: (state, {payload: error}) => (
        {
        ...state,
        newsError:error
        }
    ),
    [UNLOAD_NEWS]: () => initialState,
}, initialState);

export default news;


