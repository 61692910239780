import styled from "styled-components";
import palette from "../../lib/styles/palette";
import {useNavigate} from "react-router-dom";


const AuthTemplateBlock = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${palette.gray[2]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhiteBox = styled.div`
  .logo-area {
    display: block;
    padding-bottom: 2rem;
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
  }

  box-shadow: 0 0 0px rgba(0, 0, 0, 0.025);
  padding: 2rem;
  width: 360px;
  background: white;
  border-radius: 2px;
`;

const LogoNameBox = styled.div`
  cursor: pointer;
`;


const AuthTemplate = ({children}) => {
    const navigate = useNavigate();

    return (
        <AuthTemplateBlock>
            <WhiteBox>
                <div className="logo-area">
                    <LogoNameBox onClick={() => navigate('/')}><h4>REACT</h4></LogoNameBox>
                </div>
                {children}
            </WhiteBox>
        </AuthTemplateBlock>
    )
}
export default AuthTemplate;