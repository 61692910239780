import {call, put} from 'redux-saga/effects'
import {finishLoading, startLoading} from "../modules/loading";

export const createRequestActionTypes = type => {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;
    return [type, SUCCESS, FAILURE];
}

export default function createRequestSaga(type, request){
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return function* (action) {
        yield put(startLoading(type)); // 로딩시작

        try {
            const res = yield call(request, action.payload);
            yield put({
                type: SUCCESS,
                payload: res.data,
                meta:res
            });
        } catch (e) {
            console.log(" ## createRequestSaga error : " + e)

            yield put({
                type: FAILURE,
                payload: e,
                error: true
            });
        }
        yield put(finishLoading(type)); // 로딩 끝
    };
}