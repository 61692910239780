import Nav from "react-bootstrap/Nav";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';


import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from "react-router-dom";
import Button from "./Button";
import styled from "styled-components";

const UserInfo = styled.div`
  font-weight: 800;
  margin-right: 1rem;
`;

const MainNav = styled(Nav)`
  maxHeight: '100px'
`;



const Header = ({user, onLogout}) => {

    const navigate = useNavigate();

    const onClick = (e, key) => {
        e.preventDefault();
        if (key === "home") {
            navigate('/');
        } else {
            navigate('/news/' + key);
        }
    };
    const onClickHome = (e) => {
        e.preventDefault();
        navigate('/');
    }

    const newsCategories = [
        {name: 'all', text: '전체보기'},
        {name: 'business', text: '비즈니스'},
        {name: 'entertainment', text: '엔터테인먼트'},
        {name: 'health', text: '건강'},
        {name: 'science', text: '과학'},
        {name: 'sports', text: '스포츠'},
        {name: 'technology', text: '기술'},
    ];

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand onClick={onClickHome} style={{cursor:"pointer"}}>React-Bootstrap</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <MainNav activeKey="1"
                                 className="me-auto my-2 my-lg-0"
                                 navbarScroll
                        >
                            <Nav.Link eventKey="1" href={"#home"} key={"home"} onClick={onClickHome}>Home</Nav.Link>
                            <NavDropdown eventKey="2" href={"#news"} title="News">
                                {
                                    newsCategories.map(category => (
                                        <NavDropdown.Item eventKey={"1" + category.name}
                                                          key={category.name}
                                                          href={"#" + category.name}
                                                          onClick={
                                                              event => {
                                                                  onClick(event, category.name);
                                                              }
                                                          }
                                        >{category.text}</NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                        </MainNav>
                        <Form className="d-flex">
                            {user ? (
                                <div className="d-flex">
                                    <UserInfo className={"mt-1"}>{user.username}</UserInfo>
                                    <Button variant="outline-success" onClick={onLogout}>로그아웃</Button>
                                </div>
                            ) : (
                                <Button variant="outline-success" to={"/login"}>로그인</Button>
                            )
                            }
                        </Form>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
export default Header;