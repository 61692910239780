import {createAction, handleActions} from "redux-actions";
import createRequestSaga, {createRequestActionTypes} from "../lib/createRequestSaga";
import * as postsAPI from '../lib/api/posts';
import {takeLatest} from 'redux-saga/effects';

const UNLOAD_POST = 'post/UNLOAD_POST';
const [
    READ_POST,
    READ_POST_SUCCESS,
    READ_POST_FAILURE,
] = createRequestActionTypes('post/READ_POST');

export const readPost = createAction(READ_POST,id => id);
export const unloadPost = createAction(UNLOAD_POST);

const initialState = {
    post: null,
    postError: null
};

// ({title,body,tags})
const readPostSaga = createRequestSaga(READ_POST, postsAPI.readPost)

export function* readSaga(){
    yield takeLatest(READ_POST, readPostSaga);
}

const post = handleActions({
    [READ_POST_SUCCESS]: (state, {payload: post}) => ({
        ...state
        , post
    }),
    [READ_POST_FAILURE]: (state, {payload: error}) => (
        {
        ...state,
        postError:error
        }
    ),
    [UNLOAD_POST]: () => initialState,
}, initialState);

export default post;


