import styled from "styled-components";
import palette from "../../lib/styles/palette";
import Button from "../common/Button";
import {Link} from "react-router-dom";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useEffect, useState} from "react";

const AuthFormBlock = styled.div`
h3 {
    margin: 0 0 1rem 0;
    color: ${palette.gray[8]};
}
`;
const StyledInput = styled.input`
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid ${palette.gray[5]};
    padding-bottom: 0.5rem;
    outline: none;
    width: 100%;
    &:focus {
        color: $oc-teal-7;
        border-bottom: 1px solid ${palette.gray[7]};
    }
    & + & {
        margin-top: 1rem;
    }
`;
const Footer = styled.div`
    margin-top: 2rem;
    text-align: right;
    a {
        color: ${palette.gray[6]};
        text-decoration: underline;
        hover {
            color: ${palette.gray[9]};
        }
    }
`;
const ButtonWithMarginTop = styled(Button)`
    margin-top: 1rem;
`;
const ErrorMessage = styled.div`
    color: red;
    text-align: center;
    font-size: 0.875rem;
    margin-top: 1rem;
`;

const textMap = {
    login: '로그인',
    register: '회원가입',
}

const AuthForm = ({type, form, onChange, onSubmit, error}) => {
    const text = textMap[type];
    // bootstrap 참고
    // https://react-bootstrap.netlify.app/forms/form-control/

    const [colSize, setColSize] = useState(3);
    const [colSize01, setColSize01] = useState(9);

    useEffect(()=>{
        if(type && type ==="register" && colSize !== 3){
            setColSize(3);
            setColSize01(9);
        }
    }, [type, colSize])

    return (
        <AuthFormBlock>
            <h3>{text}</h3>
            <Form onSubmit={onSubmit}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextUsername">
                    <Form.Label column sm={colSize}>
                        아이디
                    </Form.Label>
                    <Col sm={colSize01}>
                        <Form.Control autoComplete="username" name="username" value={form.username}
                            placeholder="아이디" onChange={onChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm={colSize}>
                        비밀번호
                    </Form.Label>
                    <Col sm={colSize01}>
                        <Form.Control
                            autoComplete="new-password" name="password" value={form.password} onChange={onChange}
                            type="password" placeholder="비밀번호"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextConfirmPassword">
                    {type === 'register' && (
                        <>
                            <Form.Label column sm={colSize}>
                                확인
                            </Form.Label>
                            <Col sm={colSize01}>
                                <Form.Control
                                    autoComplete="new-password" name="confirmPassword" value={form.confirmPassword} onChange={onChange}
                                    type="password" placeholder="비밀번호 확인"/>
                            </Col>
                        </>
                        // <StyledInput
                        //     autoComplete="new-password"
                        //     name="confirmPassword"
                        //     placeholder="비밀번호 확인"
                        //     type="password"
                        //     onChange={onChange}
                        //     value={form.confirmPassword}
                        // />
                    )}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <ButtonWithMarginTop cyan={true} fullWidth={true}>{text}</ButtonWithMarginTop>
                </Form.Group>

            </Form>







            {/*<h3>{text}</h3>*/}
            {/*<form onSubmit={onSubmit}>*/}
            {/*    <StyledInput autoComplete="username" name="username" placeholder="아이디" onChange={onChange}*/}
            {/*                 value={form.username}/>*/}
            {/*    <StyledInput*/}
            {/*        autoComplete="new-password" name="password" value={form.password}*/}
            {/*        placeholder="비밀번호"*/}
            {/*        type="password"*/}
            {/*        onChange={onChange}*/}

            {/*    />*/}
            {/*    {type === 'register' && (*/}
            {/*        <StyledInput*/}
            {/*            autoComplete="new-password"*/}
            {/*            name="confirmPassword"*/}
            {/*            placeholder="비밀번호 확인"*/}
            {/*            type="password"*/}
            {/*            onChange={onChange}*/}
            {/*            value={form.confirmPassword}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*    {error && <ErrorMessage>{error}</ErrorMessage>}*/}
            {/*    <ButtonWithMarginTop cyan={true} fullWidth={true}>{text}</ButtonWithMarginTop>*/}
            {/*</form>*/}
            <Footer>
                {type === 'login' ? (<Link to="/register">회원가입</Link>) : (<Link to="/login">로그인</Link>)}
            </Footer>
        </AuthFormBlock>
    );
}
export default AuthForm;