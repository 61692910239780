import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {readPost, unloadPost} from "../../modules/post";
import PostViewer from "../../components/post/PostViewer";
import {useParams, useSearchParams} from "react-router-dom";
import {listPost} from "../../modules/posts";
import PostList from "../../components/post/PostList";

const PostListContainer = () => {
    let {username} = useParams();
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    const {posts, error, loading, user} = useSelector(
        ({posts, loading, user}) => ({
            posts: posts.posts,
            error: posts.error,
            loading: loading['posts/LIST_POST'],
            user: user.user
        })
    )

    useEffect(() => {
        const tag = searchParams.get('tag');
        const page = parseInt(searchParams.get('page'), 10) || 1;
        dispatch(listPost({tag, username, page}));
    }, [dispatch, username, searchParams]);
    return (
        <PostList
            loading={loading}
            error={error}
            posts={posts}
            showWriteButton={user}
        />
    );

};

export default PostListContainer;