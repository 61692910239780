import client from "./client";

// 로그인
export const login = ({username, password}) => client.post('/api/auth/login', {username, password});
export const register = ({username, password}) => client.post('/api/auth/register', {username, password});
export const check = () => client.post('/api/auth/check');
export const news = () => client.get('/api/news');
/**
 * 로그아웃
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const logout = () => client.post('/api/auth/logout');







