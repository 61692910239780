import AuthForm from "../../components/auth/AuthForm";
import {useDispatch, useSelector} from "react-redux";
import {changeField, initializeForm, register} from "../../modules/auth";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const RegisterForm = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {form, auth, authError, user} = useSelector(({auth, user}) => ({
        form: auth.register,
        auth: auth.auth,
        authError: auth.authError,
        user: user.user

    }));

    // 인풋 변경 이벤트 핸들러
    const onChange = e => {
        const {value, name} = e.target;
        dispatch(
            changeField({
                form: 'register',
                key: name,
                value
            })
        );
    }

    // 폼 등록 이벤트 핸들러
    const onSubmit = e => {
        e.preventDefault();
        // 구현예정
        const {username, password, confirmPassword} = form;
        if ([username, password, confirmPassword].includes('')) {
            setError('빈 칸을 모두 입력하세요...');
            return;
        }

        if (password !== confirmPassword) {
            setError('비밀번호가 일치하지 않습니다....');
            dispatch(changeField({form: 'register', key: 'password', value: ''}))
            dispatch(changeField({form: 'register', key: 'confirmPassword', value: ''}))
            return;
        }
        dispatch(register({username, password}));
    }

    // 컴포넌트가 처음 렌더링될 때 form을 초기화함
    useEffect(() => {
            dispatch(initializeForm('register'));
        }, [dispatch]
    );

    // 컴포넌트가 처음 렌더링될 때 form을 초기화함
    useEffect(() => {
            if (authError) {
                // 계정명이 이미 존재할 때
                if (authError.response.status === 409) {
                    setError("생성할 수 없는 계정입니다.");
                    return;
                }
                setError("회원가입 실패");
                return;
            }

            if (auth) {
                // login 화면으로 이동
                alert(" 회원가입에 성공하였습니다.")
                navigate('/login');
                // dispatch(check());
            }
        }, [auth, authError, dispatch]
    );
    //
    // // user 값이 잘 설정되었는지 확인
    useEffect(() =>
        {
            if (user) {
                navigate('/');  // 홈 화면으로 이동
                try{
                    localStorage.setItem("user", JSON.stringify(user));
                }catch (e) {
                    console.log(" localstorage is not working");
                }

            }
        }, [navigate, user]
    );
    return (
        <AuthForm type={"register"}
                  form={form}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  error={error}
        />
    );
}
export default RegisterForm;