import {createAction, handleActions} from "redux-actions";
import createRequestSaga, {createRequestActionTypes} from "../lib/createRequestSaga";
import * as postsAPI from '../lib/api/posts';
import {takeLatest} from 'redux-saga/effects';

const [
    LIST_POST,
    LIST_POST_SUCCESS,
    LIST_POST_FAILURE,
] = createRequestActionTypes('posts/LIST_POST');

export const listPost = createAction(LIST_POST,({tag, username, page}) => ({tag, username, page}));

const initialState = {
    posts: null,
    error: null,
    lastPage: 1,
};

const listPostSaga = createRequestSaga(LIST_POST, postsAPI.listPost)

export function* postListSaga(){
    yield takeLatest(LIST_POST, listPostSaga);
}

const posts = handleActions({
    [LIST_POST_SUCCESS]: (state, {payload: posts, meta:res }) => ({
        ...state
        , posts
        , lastPage: parseInt(res.headers['last-page'],10)       // 문자열을 숫자로 변환
    }),
    [LIST_POST_FAILURE]: (state, {payload: error}) => (
        {
        ...state,
        error
        }
    ),
}, initialState);

export default posts;


